<template>
  <div class="d-flex flex-column align-items-center col-12 vue-container">
    <div class="d-flex flex-column col-12 mobile-header" v-if="windowWidth <= 500">
      <div class="welcome-text"> Welcome to the</div>
      <div class="mt-2 penrose-dashboard-text">PENROSE INTERNAL DASHBOARD</div>
    </div>
    <div class="d-flex flex-column text-center align-items-center col-12 mobile-header" v-if="windowWidth >= 500">
      <div class="welcome-text"> Welcome to the</div>
      <div class="mt-2 penrose-dashboard-text">PENROSE INTERNAL DASHBOARD</div>
    </div>
    <div class="d-flex flex-column col-11 col-xl-3 details-container mt-4">
      <div class="input-header">Please add your details</div>
      <div class="d-flex flex-column align-items-center">
        <input v-model="name" type="text" class="mt-3 col-11" placeholder="Name" required>
        <input v-model="surname" type="text" class="mt-3 col-11" placeholder="Surname" required>
        <input v-model="msisdn" v-on:change="msisdnConversion" type="text" class="mt-3 col-11" placeholder="Cellphone Number" required>
        <input v-model="email" id="emailAddress" type="email" class="mt-3 col-11" required placeholder="Email">
      </div>
      <div class="mt-3 input-header">Date of birth</div>
      <div class="d-flex flex-column align-items-center">
        <input v-model="birthDate" type="date" class="mt-3 col-11" required>
      </div>
      <div class="mt-3 input-header">Security Question</div>
      <div class="d-flex flex-column align-items-center">
        <input v-model="teamSoccer" type="text" class="mt-3 col-11" placeholder="Favorite Football Team" required>
      </div>
      <div class="mt-3 input-header">Next of Kin</div>
      <div class="d-flex flex-column align-items-center">
        <input v-model="kinName" type="text" class="mt-3 col-11" placeholder="Name & Surname" required>
        <input v-model="kinNumber" type="text" class="mt-3 col-11" placeholder="Cellphone Number" required>
      </div>
      <div class="mt-3 input-header">Create your Password</div>
      <div class="d-flex flex-column align-items-center">
        <input v-model="password" type="password" class="mt-3 col-11" placeholder="Password" required>
      </div>
      <div class="mt-3 input-header">Penrose Registration Key</div>
      <div class="d-flex flex-column align-items-center">
        <input v-model="registrationKey" type="text" class="mt-3 col-11" placeholder="Unique Key" required>
      </div>
      <div class="mt-3 input-header">Add a Profile Picture</div>
      <div class="d-flex flex-column align-items-center justify-content-center  align-content-center">
        <img src="../../assets/default-profile-icon.png" style="width: 110px" id="profile-img" class="mt-1">
        <input type="file" id="file" accept="" @change="handleImageUpload($event)" style="font-size: 10px; padding-left: 50px; padding-top: 8px">
      </div>
      <div class="d-flex flex-column align-items-center">
        <button @click="createAccountClicked()" class="mt-3 col-7 create-account-button">Create account</button>
        <img class="mt-5 mb-4 col-6" src="../../assets/penrose-logo.png">
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import bcrypt from 'bcryptjs';
import {BlobServiceClient} from '@azure/storage-blob';
import DashboardService from "@/services/dashboardService";
import {environmentConfig} from "@/config/environmentConfig";

import {ref} from 'vue'

const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

export default {
  name: 'CreateAccount',
  computed: {
    ...mapState(['userData']),
  },
  data() {
    return {
      windowWidth: windowWidth,
      name: undefined,
      surname: undefined,
      msisdn: undefined,
      email: undefined,
      teamSoccer: undefined,
      birthDate: undefined,
      kinName: undefined,
      kinNumber: undefined,
      password: undefined,
      hashedPassword: undefined,
      registrationKey: undefined,
      profileImg: undefined,
      registrationKeyValid: undefined,
    }
  },
  methods: {
    ...mapMutations(['setUserData']),
    handleImageUpload(event) {
      this.file = event.target.files[0];
      const output = document.getElementById('profile-img');
      this.profileImg = output;
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src);
      };
    },
    async createAccountClicked() {
      await this.registerUser();
      await this.uploadImageBlobToAzure();
      this.validateAccountCreation();
    },
    validateAccountCreation() {
      if (this.registrationKeyValid === true && this.userData) {
        this.routeToPage('dashboard');
      } else {
        console.log('Create account error')
      }
    },
    hashPassword(password) {
      return bcrypt.hashSync(password, 10);
    },
    async registerUser() {
      const hashedPassword = this.hashPassword(this.password);

      const response = await DashboardService.registerNewUser({
        name: this.name,
        surname: this.surname,
        msisdn: this.msisdn,
        email: this.email,
        birthDate: this.birthDate,
        teamSoccer: this.teamSoccer,
        kinName: this.kinName,
        kinNumber: this.kinNumber,
        hashedPassword,
        registrationKey: this.registrationKey,
      });
      this.setUserData(response.data.UserData);
      this.registrationKeyValid = response.data.Key;
    },
    msisdnConversion() {
      if (this.msisdn.startsWith('0')) {
        this.msisdn = this.msisdn.slice(1);
        const saNumber = '27';
        this.msisdn = parseInt(saNumber + this.msisdn, 10);
      } else if (this.msisdn.startsWith('+')) {
        this.msisdn = this.msisdn.slice(1);
      }
    },
    async uploadImageBlobToAzure() {
      const account = environmentConfig.azureAccount;
      const sas = environmentConfig.blobSas;
      const blobServiceClient = new BlobServiceClient(
          `https://${account}.blob.core.windows.net${sas}`,
      );
      const containerName = environmentConfig.azureContainerName;
      const content = this.name + '_' + this.surname;
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blockBlobClient = containerClient.getBlockBlobClient(content);
      const options = {blobHTTPHeaders: {blobContentType: this.file.type}};
      await blockBlobClient.uploadData(this.file, options);
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`)
    },
  },
  mounted() {
    window.addEventListener('resize', handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', handleResize)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap');

.vue-container {
  padding-top: 10px;
}

.mobile-header {
  margin-left: 40px;
}

.welcome-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
}

.penrose-dashboard-text {
  margin-top: -20px;
  font-size: 50px;
  color: #EE5C36;
  line-height: 1.1;
}

.create-account-button {
  background-color: #EE5C36;
  color: #FFFFFF;
  border: 0;
  border-radius: 12px;
  height: 40px;
}

input {
  border: 0;
  height: 50px;
  border-radius: 12px;
  width: 270px;
  font-size: 14px;
  padding-left: 10px;
}

::placeholder {
  padding-left: 10px;
  font-size: 14px;
}

@media screen and (max-width: 500px ) {
  .penrose-dashboard-text {
    font-size: 45px;
  }

  .input-header {
    font-size: 18px;
  }
}
</style>
